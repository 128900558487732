import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AbstractCheckboxComponent } from '@bc-common-lib';

@Component({
  selector: 'us2-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
  ],
  imports: [
    MatIconModule
  ]
})
export class CheckboxComponent extends AbstractCheckboxComponent {
  //@Output() changed = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
